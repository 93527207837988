(function() {
    const body = document.body;
    const switches = document.querySelectorAll('.theme-switcher');

    const lightThemeClass = 'light-theme';
    const darkThemeClass = 'dark-theme';
    const key = 'theme';

    switches.forEach(s => s.addEventListener('click', (evt) => {
        if(body.classList.contains(lightThemeClass)) {
            body.classList.add(darkThemeClass);
            body.classList.remove(lightThemeClass);
            localStorage.setItem(key, darkThemeClass);
        } else {
            body.classList.remove(darkThemeClass);
            body.classList.add(lightThemeClass);
            localStorage.setItem(key, lightThemeClass);
        }
    }));

    setDefaultTheme();
    
    function setDefaultTheme() {
        const theme = localStorage.getItem(key);
        if(theme) {
            body.classList.add(theme);
        }
    }
})();